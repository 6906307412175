import axios from 'axios'

// Called from a post render hook on a Page/Component.
// Returned data to be mapped to state of Page/Component which will trigger a
// re-render with that data being displayed.

export const getAllVillages = (endPointHost) =>
  axios
    .get(`${endPointHost}/api/Villages/All`)
    .then((response) => {
      return response && response.data
    })
    .catch((error) => {
      return error
    })

export const getJourneysForVillage = (endPointHost, locale, villageCode) =>
  axios
    .get(
      `${endPointHost}/api/ShoppingExpress/Journeys?cultureCode=${locale}&villageCode=${villageCode}`
    )
    .then((response) => {
      return response && response.data
    })
    .catch((error) => {
      return error
    })

export const getServiceAvailabilityForVillage = (
  endPointHost,
  locale,
  villageCode
) =>
  axios
    .get(
      `${endPointHost}/api/Services/Availability?cultureCode=${locale}&villageCode=${villageCode}&providerId=1`
    )
    .then((response) => {
      return response && response.data
    })
    .catch((error) => {
      return error
    })

export const initBasket = (endPointHost, villageCode) =>
  axios
    .get(`${endPointHost}/api/Villages/Single?villageCode=${villageCode}`)
    .then((response) => {
      return axios({
        method: 'post',
        url: `${endPointHost}/api/ShoppingCart/CreatCart`,
        data: response.data.data.Village,
      })
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return error
    })

export const initShoppingExpress = (endPointHost, locale, villageCode) =>
  axios
    .all([
      getJourneysForVillage(endPointHost, locale, villageCode),
      getServiceAvailabilityForVillage(endPointHost, locale, villageCode),
      axios.get(
        `${endPointHost}/api/Villages/Single?villageCode=${villageCode}`
      ),
    ])
    .then(
      axios.spread((journeys, availability, village) => {
        const result = Object.assign(
          { journeys },
          { availability },
          { village }
        )
        return result
      })
    )

export const getPackages = (endPointHost, locale, villageCode, fromDate) =>
  axios
    .get(
      `${endPointHost}/api/ShoppingPackage/Routes?villageCode=${villageCode}&cultureCode=${locale}&from=${fromDate}`
    )
    .then((response) => {
      return response && response.data
    })
    .catch((error) => {
      return error
    })

export const AddItemToCart = async (endPointHost, villageCode, item) => {
  let basketId = localStorage.getItem('ECOM')
  if (!basketId) {
    basketId = await initBasket(endPointHost, villageCode).then((response) => {
      return response && response.data.Basket.Id
    })
  }

  return axios
    .get(`${endPointHost}/api/Villages/Single?villageCode=${villageCode}`)
    .then((responseVillage) => {
      return axios({
        method: 'put',
        url: `${endPointHost}/api/ShoppingCart/UpsertItem`,
        data: {
          BasketId: basketId,
          Item: {
            ...item,
            Village: { ...responseVillage.data.data.Village },
          },
        },
      })
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return error
    })
}

export const getBooking = (
  endPointHost,
  locale,
  villageCode,
  emailAddress,
  jobId
) =>
  axios
    .get(
      `${endPointHost}/api/Booking/BookedCart?villageCode=${villageCode}&cultureCode=${locale}&emailAddress=${emailAddress}&jobId=${jobId}`
    )
    .then((response) => {
      return response && response.data
    })
    .catch((error) => {
      return error
    })
